import { h, render, Fragment } from "preact";

const features = [
  {
    name: "Apply Easily and Securely",
    description: "Fill out our secure online application in just a few short steps.",
  },
  {
    name: "Trust Through Visibility",
    description: "Login and confirm your personal information so we know we're sending funds to the right place.",
  },
  {
    name: "Sign your loan agreement",
    description: "Review your new loan agreement before signing online.",
  },
  {
    name: "Receive your funds",
    description: "If approved, funds will be deposited into your checking account as soon as the next business day.",
  },
];

export default function Borrow() {
  return (
    <div className="bg-white py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl sm:text-center">
          <p className="mt-2 text-pretty text-4xl font-faust font-semibold tracking-tight text-[#411f01] sm:text-balance sm:text-5xl">
            Getting the Funds You Need, Made Simple and Secure
          </p>
          <p className="mt-6 font-faust text-lg/8 text-[#411f01]">
            From the comfort of your home or on the go, Lucent Cash protects your information while providing access to the funds you need.
          </p>
        </div>
      </div>
      <div className="relative overflow-hidden pt-16">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <img
            alt="App screenshot"
            src="/public/src/img/divspending.jpg"
            width={2432}
            height={1442}
            className="mb-[-12%] rounded-xl shadow-2xl ring-1 ring-gray-900/10"
          />
          <div aria-hidden="true" className="relative">
            <div className="absolute -inset-x-20 bottom-0 bg-gradient-to-t from-white pt-[7%]" />
          </div>
        </div>
      </div>
      <div className="mx-auto mt-16 max-w-7xl px-6 sm:mt-20 md:mt-24 lg:px-8">
        <dl className="mx-auto grid max-w-2xl grid-cols-1 gap-x-6 gap-y-10 text-base/7 text-gray-600 sm:grid-cols-2 lg:mx-0 lg:max-w-none lg:grid-cols-4 lg:gap-x-8 lg:gap-y-16">
          {features.map((feature) => (
            <div key={feature.name} className="relative pl-9 flex flex-col">
              <dt className="inline font-semibold font-inter text-[#411f01]">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="absolute left-1 top-1 size-6 text-portmain-300">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                </svg>
                {feature.name}
              </dt>{" "}
              <dd className="inline font-inter">{feature.description}</dd>
            </div>
          ))}
        </dl>
      </div>
      <div className="flex justify-center items-center px-6 mt-12 md:px-8">
        <a href="/apply-now" className="transition-colors duration-300 ease-in-out text-white bg-portmain-300 hover:bg-portmain-400 no-underline font-inter text-center px-[3rem] py-[0.75rem] rounded-md shadow-sm text-lg font-medium" style="box-shadow: #f29f57 0px 10px 40px -5px;">
          Get Started
        </a>
      </div>
    </div>
  );
}
