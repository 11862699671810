import { h, render, Fragment } from "preact";

export default function About() {
  return (
    <div className="overflow-hidden bg-white font-faust py-24 sm:py-32">
      <div className="mx-auto max-w-2xl px-6 lg:max-w-7xl lg:px-8">
        <div className="max-w-4xl">
          <h1 className="mt-2 text-pretty text-4xl font-semibold tracking-tight text-[#411f01] sm:text-5xl">
            From Us, To You
          </h1>
          <p className="mt-6 text-balance text-xl/8 text-[#411f01]">
            Seize the day with Lucent Cash, your trusted partner in flexible installment loans. We're committed to empowering individuals through transparent, personalized service and competitive rates.
          </p>
        </div>
        <section className="mt-20 grid grid-cols-1 lg:grid-cols-2 lg:gap-x-8 lg:gap-y-8">
          <div className="lg:pr-8">
            <h2 className="text-pretty text-2xl font-semibold tracking-tight text-[#411f01]">Who We Are</h2>
            <p className="mt-6 text-base/7 text-[#411f01]">
              At Lucent Cash, we are dedicated to providing solutions that empower individuals to meet their financial needs with confidence and ease. Specializing in installment loan lending, we prioritize secured payments and competitive rates to ensure our customers have access to reliable and affordable options.
            </p>
            <p className="mt-8 text-base/7 text-[#411f01]">
              Our mission is to simplify borrowing through a transparent and user-friendly process. Whether it's savoring your morning coffee or planning your next big step, Lucent Cash is here to ensure that managing your financial needs fits effortlessly into your life.
            </p>
          </div>
          <div className="pt-16 lg:row-span-2 lg:-mr-16 xl:mr-auto">
            <div className="-mx-8 grid grid-cols-1 gap-4 sm:-mx-16 sm:grid-cols-1 lg:mx-0 lg:grid-cols-2 lg:gap-4 xl:gap-8">
              <div className="hidden lg:block aspect-square overflow-hidden rounded-xl shadow-xl outline outline-1 -outline-offset-1 outline-black/10">
                <img
                  alt=""
                  src="/public/src/img/divimg_2.jpg"
                  className="block size-full object-cover"
                />
              </div>
              <div className="hidden lg:block -mt-8 aspect-square overflow-hidden rounded-xl shadow-xl outline outline-1 -outline-offset-1 outline-black/10 lg:-mt-40">
                <img
                  alt=""
                  src="/public/src/img/divimg_1.jpg"
                  className="block size-full object-cover"
                />
              </div>
              <div className="hidden lg:block aspect-square overflow-hidden rounded-xl shadow-xl outline outline-1 -outline-offset-1 outline-black/10">
                <img
                  alt=""
                  src="/public/src/img/divimg_4.jpg"
                  className="block size-full object-cover"
                />
              </div>
              <div className="-mt-8 aspect-square overflow-hidden flex justify-center items-center lg:flex-none rounded-xl lg:shadow-xl outline outline-1 -outline-offset-1 outline-black/10 lg:-mt-40">
                <img
                  alt=""
                  src="/public/src/img/divimg_3.jpg"
                  className="block size-9/12 lg:size-full rounded-xl lg:rounded-none object-cover"
                />
              </div>
            </div>
          </div>
          <div className="max-lg:mt-16 lg:col-span-1">
            <h2 className="text-pretty text-2xl font-semibold tracking-tight text-[#411f01]">Our Strengths</h2>
            <hr className="mt-6 border-t border-gray-400" />
            <dl className="mt-6 grid grid-cols-1 gap-x-8 gap-y-2 sm:grid-cols-3">
              <div className="flex flex-col gap-y-2 pb-4">
                <dt className="text-base/6 text-[#411f01]">Apply <span className="text-portmain-300">online anytime</span>, anywhere you have internet access.</dt>
                <dd className="order-first text-xl text-[#411f01] font-semibold tracking-tight">
                  1. Convenient
                </dd>
              </div>
              <div className="flex flex-col gap-y-2 pb-4">
                <dt className="text-base/6 text-[#411f01]">Trust Lucent Cash to keep your <span className="text-portmain-300">information protected</span>.</dt>
                <dd className="order-first text-xl text-[#411f01] font-semibold tracking-tight">
                  2. Secure
                </dd>
              </div>
              <div className="flex flex-col gap-y-2 max-sm:pb-4">
                <dt className="text-base/6 text-[#411f01]">Our <span className="text-portmain-300">friendly and welcoming</span> Customer Care Team is here for you.</dt>
                <dd className="order-first text-xl text-[#411f01] font-semibold tracking-tight">
                  3. Supportive
                </dd>
              </div>
            </dl>
          </div>
          <div className="flex justify-center items-center px-6 md:px-8">
            <a href="/apply-now" className="transition-colors duration-300 ease-in-out text-white bg-portmain-300 hover:bg-portmain-400 no-underline font-inter text-center px-[3rem] py-[0.75rem] rounded-md shadow-sm text-lg font-medium" style="box-shadow: #f29f57 0px 10px 40px -5px;">
              Get Started
            </a>
          </div>
        </section>
      </div>
    </div>
  );
}
