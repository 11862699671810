import type { CustomFlowbiteTheme } from 'flowbite-react';

const flowbiteTheme: CustomFlowbiteTheme = {
    navbar: {
        link: {
            base: 'relative inline-flex items-center justify-left rounded-md p-2 focus:outline-none ' +
                // Begin portfolio customizations
                'text-white md:text-white hover:bg-[#dd612e] hover:text-white px-3 py-2 text-base font-medium ' +
                'w-full md:w-auto',
            active: {
                on: '',
                off: '',
            },
        },
        toggle: {
            base: 'relative inline-flex items-center rounded-lg p-2 text-sm focus:outline-none ' +
                'focus:ring-2 focus:ring-inset focus:ring-white md:hidden ' +
                // Begin portfolio customizations
                'text-white hover:bg-[#dd612e]',
            icon: 'h-6 w-6 shrink-0',
        },
        collapse: {
            base: 'w-full md:block md:w-auto ' +
                // Begin portfolio customizations
                '',
            list: 'mt-4 flex flex-col md:mt-0 md:flex-row md:space-x-8 md:text-sm md:font-medium block rounded-md px-3 py-2 text-base ' +
                // Begin portfolio customizations
                'bg-[#381b13] md:bg-transparent text-white',
            hidden: {
                on: 'hidden',
                off: '',
            },
        },
    },
};

export default flowbiteTheme;
