import { h, render, Fragment } from "preact";

const benefits = [
  "Simple Application",
  "Affordable Options",
  "Quick Approvals",
  "Reliable Payments",
];

export default function CTA() {
  return (
    <div className="bg-white py-24 sm:py-32">
      <div className="relative isolate">
        <div className="mx-auto max-w-7xl sm:px-6 lg:px-8">
          <div className="mx-auto flex max-w-2xl flex-col gap-16 bg-portmain-300/5 px-6 py-16 ring-1 ring-portmain-300/10 sm:rounded-3xl sm:p-8 lg:mx-0 lg:max-w-none lg:flex-row lg:items-center lg:py-20 xl:gap-x-20 xl:px-20">
            <img
              alt=""
              src="/public/src/img/divcta.jpg"
              className="h-96 w-full flex-none rounded-2xl object-cover shadow-xl lg:aspect-square lg:h-auto lg:max-w-sm"
            />
            <div className="w-full flex-auto">
              <h2 className="text-pretty text-4xl font-semibold font-faust tracking-tight text-[#411f01] sm:text-5xl">
                Ready to start?
              </h2>
              <p className="mt-6 text-pretty font-faust text-lg/8 text-[#411f01]">
                With competitive rates and a stress-free process, click below to let Lucent Cash cover you brilliantly!
              </p>
              <ul role="list" className="mt-10 grid grid-cols-1 font-inter gap-x-8 gap-y-3 text-base/7 text-[#411f01] sm:grid-cols-2">
                {benefits.map((benefit) => (
                  <li key={benefit} className="flex gap-x-3">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="h-7 w-5 flex-none text-portmain-300">
                      <path strokeLinecap="round" strokeLinejoin="round" d="M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                    </svg>
                    {benefit}
                  </li>
                ))}
              </ul>
              <div className="flex justify-center items-center px-6 mt-12 md:px-8">
                <a href="/apply-now" className="transition-colors duration-300 ease-in-out text-white bg-portmain-300 hover:bg-portmain-400 no-underline font-inter text-center px-[3rem] py-[0.75rem] rounded-md shadow-sm text-lg font-medium" style="box-shadow: #f29f57 0px 10px 40px -5px;">
                  Get Started
                </a>
              </div>
            </div>
          </div>
        </div>
        <div
          aria-hidden="true"
          className="absolute inset-x-0 -top-16 -z-10 flex transform-gpu justify-center overflow-hidden blur-3xl"
        >
          <div
            style={{
              clipPath:
                "polygon(73.6% 51.7%, 91.7% 11.8%, 100% 46.4%, 97.4% 82.2%, 92.5% 84.9%, 75.7% 64%, 55.3% 47.5%, 46.5% 49.4%, 45% 62.9%, 50.3% 87.2%, 21.3% 64.1%, 0.1% 100%, 5.4% 51.1%, 21.4% 63.9%, 58.9% 0.2%, 73.6% 51.7%)",
            }}
            className="aspect-[1318/752] w-[82.375rem] flex-none bg-gradient-to-r from-[#fcddbb] to-[#dd612e] opacity-25"
          />
        </div>
      </div>
    </div>
  );
}
