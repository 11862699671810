import { h, render, Fragment } from 'preact';

export default function Content() {
    return (
        <div className="relative bg-white">
            <img
                alt=""
                src="/public/src/img/divshopping.jpg"
                className="h-56 w-full bg-gray-50 object-cover rounded-r-xl lg:absolute lg:inset-y-0 lg:left-0 lg:h-full lg:w-1/2"
            />
            <div className="mx-auto grid max-w-7xl lg:grid-cols-2">
                <div className="px-6 pb-24 pt-16 sm:pb-32 sm:pt-20 lg:col-start-2 lg:px-8 lg:pt-32">
                    <div className="mx-auto max-w-2xl lg:mr-0 lg:max-w-lg">
                        <p className="mt-2 text-pretty text-4xl font-faust font-semibold tracking-tight text-[#411f01] sm:text-5xl">
                            Why Lucent Cash Shines
                        </p>
                        <p className="mt-6 text-lg/8 font-faust text-[#411f01]">
                            Lucent Cash stands out by prioritizing what truly matters to our customers—transparency, convenience, and trust. We understand the challenges of receiving funds, which is why we offer installment loans with competitive rates and a streamlined process that takes the stress out of borrowing. Our secure payment solutions and fast approvals ensure you have the support you need without the added worry.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}
